<script setup lang="ts">
import type { AuthAuthGetCipherRouteRpc, AuthAuthLoginByEmailRouteRpc } from '@repo/contract';
import { FetchError } from 'ofetch';
import { useNotification } from '../../../shared/composables/notification/use-notification';
import { navigateTo, onMounted, ref, watch } from '#imports';
import { useLoginValidation } from '~/pages/login/composables/use-login-validation';
import BaseButton from '~/shared/components/buttons/base-button.vue';
import BaseClickableText from '~/shared/components/buttons/base-clickable-text.vue';
import BaseFormGroup from '~/shared/components/form/base-form-group.vue';
import BaseInput from '~/shared/components/form/base-input.vue';

const data = ref({
  email: '',
  password: '',
});

const isLoginButtonDisabled = ref(false);

const { hasErrors, trigger } = useLoginValidation(data);

onMounted(() => {
  trigger();

  watch(hasErrors, () => {
    isLoginButtonDisabled.value = hasErrors.value;
  }, { immediate: true });
});

const { showNotification } = useNotification();

const error = ref('');

watch([() => data.value.email, () => data.value.password], () => {
  error.value = '';
});

async function onSubmit() {
  if (hasErrors.value) return;

  const params = {
    email: data.value.email,
    password: data.value.password,
  } as typeof AuthAuthLoginByEmailRouteRpc.request;

  try {
    await $fetch('/api/rpc/auth/auth/login-by-email', {
      method: 'POST',
      body: params,
    });

    navigateTo('/');
  }
  catch (err) {
    if (err instanceof FetchError) {
      if (err.response.status === 401) {
        error.value = 'Invalid Email or Password';

        return;
      }
    }

    showNotification({
      type: 'error',
      message: 'Server Error. Please try again.',
    });
  }
}
</script>

<template>
  <form class="flex flex-col w-full items-center gap-29.5" @submit.prevent="onSubmit">
    <div class="flex flex-col gap-2">
      <div class="flex flex-col gap-4 w-80 relative">
        <span v-if="error" class="absolute top--9 left-0 c-error-pure">
          {{ error }}
        </span>
        <BaseFormGroup name="email" error="" label="Email">
          <BaseInput
            id="email" v-model="data.email" name="email" placeholder="Enter Email" :error="error"
          />
        </BaseFormGroup>

        <BaseFormGroup name="password" error-disabled label="Password">
          <BaseInput
            id="password" v-model="data.password" name="password" type="password" placeholder="Enter Password" :error="error"
          />
        </BaseFormGroup>
      </div>

      <div class="flex justify-end">
        <BaseClickableText to="/forgot-password" text="Forgot Password?" />
      </div>
    </div>

    <div class="flex flex-col gap-10 items-center">
      <BaseButton
        variant="primary"
        type="submit"
        :disabled="isLoginButtonDisabled"
      >
        Login
      </BaseButton>

      <p>Don't have an account? <BaseClickableText to="/register" text="Register here" /></p>
    </div>
  </form>
</template>
